// BASE COLORS

$base-white: #FCFCFC;
$base-black: #0f0f0f;

$dark-mode-gray-25: #FCFCFC;
$dark-mode-gray-200: #E5E5E5;
$dark-mode-gray-300: #D6D6D6;
$dark-mode-gray-400: #A3A3A3;
$dark-mode-gray-800: #292929;
$dark-mode-gray-900: #141414;

$paladin-500: #FA8C16;
$paladin-600: #D46B08;
$paladin-700: #AD4E00;

$quest-500: #81E17C;
$quest-600: #68B463;

$warlord-blood-500: #FF4405;
$warlord-blood-600: #E62E05;

$dullahan-500: #7A5AF8;
$dullahan-600: #6938EF;

$boost-500: #7fd042;
$boost-600: #66C61C;

// VARS
$title: $base-white;
$subtitle: $dark-mode-gray-400;
$paladin-title: $paladin-600;
$text: $base-white;

$box-bg: $dark-mode-gray-900;
$box-stroke: $dark-mode-gray-800;

$primary-button-bg: $paladin-600;
$primary-button-text: $base-white;
$primary-button-bg-quest: $quest-600;
$secondary-button-pal-bg: $dark-mode-gray-800;
$secondary-button-pal-text: $paladin-600;
$secondary-button-stroke: $dark-mode-gray-800;
$primary-button-bg-warlord: $warlord-blood-600;
$primary-button-bg-dullahan: $dullahan-600;
$primary-button-bg-boost: $boost-600;