//// FONT FAMILIES ////
@font-face {
  font-family: "DM Sans Bold";
  src: url(./fonts/DMSans-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}
@font-face {
  font-family: "DM Sans Bold Italic";
  src: url(./fonts/DMSans-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "DM Sans Italic";
  src: url(./fonts/DMSans-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "DM Sans Medium";
  src: url(./fonts/DMSans-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DM Sans Medium Italic";
  src: url(./fonts/DMSans-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "DM Sans";
  src: url(./fonts/DMSans-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Public Sans";
  src: url(./fonts/PublicSans-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Public Sans Semi Bold";
  src: url(./fonts/PublicSans-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: bold;
  font-display: swap;
}

//// FONT SIZES ////
$font-xs: 0.75em;
$font-s: 0.875em;
$font-m: 1em;
$font-l: 1.125em;
$font-ll: 1.25em;
$font-xl: 1.5em;
