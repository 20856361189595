@import './colors.scss';
@import './responsive.scss';

@mixin subtitle {
  color: $subtitle;
  font-family: 'DM Sans Medium', sans-serif;
  font-size: 1.25em;
  line-height: 1.875rem;
  @include screen($smartphone) {
    font-size: 1em;
    line-height: 1.5rem;
  }
}

@mixin button {
  border-radius: 0.5em;
  color: $primary-button-text;
  font-family: 'DM Sans Bold', sans-serif;
  font-size: 1em;
  padding: 0.75em 1.25em;
  &:hover {
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.25s ease-in-out;
  }
}

@mixin btn-border-anim(
  $color: $base-white, 
  $hover: black, 
  $width: 2px, 
  $vertical: top, 
  $horizontal: left, 
  $duration: 0.4s,
  $radius: 0.5em
  ) {
  transition: color $duration calc($duration / 3) ease-in-out;
  position: relative;
  &::before,
  &::after {
    border: 0 solid transparent;
    border-radius: $radius;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    
    #{$vertical}: 0; 
    #{$horizontal}: 0;
    bottom: 3px;
  }
  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }
  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }
  &:hover {
    color: $color;
    &::before {
      transition-delay: border-color 0.4s;
      bottom: 0;
    }
    &::after {
      top: 0;
    }
    &::before,
    &::after {
      border-color: $hover;
      border-radius: $radius;
      bottom: 0;
      transition-delay: bottom 0.25s;
      transition: 
        border-color $duration, 
        border-radius 0.5s,
        width $duration, 
        height $duration;
      width: 100%;
      height: 100%;
    }
    
    &::before { transition-delay: 0.25s, 0s, $duration; }
    
    &::after { transition-delay: 0s, 0.25s, 0s; }
  }
}


@mixin pal-button {
  background-color: $primary-button-bg;
}

@mixin quest-button {
  background-color: $primary-button-bg-quest;
}

@mixin warlord-button {
  background-color: $primary-button-bg-warlord;
}

@mixin dullahan-button {
  background-color: $primary-button-bg-dullahan;
}

@mixin boost-button {
  background-color: $primary-button-bg-boost;
}