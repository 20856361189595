@import '../../styles/mixins.scss';

.main-section {
  img {
    width: 377px;
  }
}
.main {
  padding: 0;
  .main-section {
    margin-top: 64px; /* navbar height */
    padding-top: 5.5em;
    max-width: 1020px;
    .content {
      padding-top: 0;
    }
  }
}
.home-title {
  margin-bottom: 0.5em;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  text-wrap: balance;
  @include screen($smartphone) {
    font-size: 2.5em;
    line-height: 3.125rem;
  }
}

.subtitle {
  @include subtitle;
  margin-bottom: 0.5em;
  max-width: 400px;
}

.subtitle-secondary {
  @include subtitle;
  font-family: 'DM Sans', sans-serif;
}