// BREAKPOINTS //
$tablet: 769px;
$smartphone: 550px;

@mixin screen($breakpoint) {
  @if $breakpoint == $tablet {
    @media (max-width: $breakpoint) {
      @content;
    }
  }
  @else if $breakpoint == $smartphone {
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}