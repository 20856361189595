@import 'colors.scss';
@import 'mixins.scss';
@import 'responsive.scss';

html {
  background-color: $base-black;
  color: $base-white;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  scroll-behavior: smooth;
  overflow-x: hidden;
  h1,
  h2 {
    color: $title;
    font-family: 'Public Sans', sans-serif;
    font-size: 4em;
    font-weight: bold;
    line-height: 1em;
    overflow-wrap: break-word;
    &.secondary-pal {
      color: $paladin-600;
    }
    @include screen($smartphone) {
      font-size: 2.5em;
      line-height: 1.25em;
    }
  }
  nav {
    font-family: 'DM Sans', sans-serif; /* override */
  }
}
.container {
  background: radial-gradient(50% 50% at 50% 90%, rgba(237, 42, 36, 0.40) 0%, rgba(243, 208, 27, 0.00) 100%);
  background-attachment: fixed;
  background-size: 100vw 150vh;
  &.scrolled {
    background: none;
    .sub-container {
      background-color: $base-black;
      transition: background-color 0.8s ease-in-out;
    }
  }
}

.button {
  @include button;
  @include btn-border-anim($paladin-600, $paladin-600, 1px, bottom, right);
}

.button-group {
  gap: 1.5em;
  a {
    padding: 0.75em 1.5em;
  }
}

.button-secondary {
  @include button;
  border: 1px solid $dark-mode-gray-800;
  color: $base-white;
  @include btn-border-anim($dark-mode-gray-400, $dark-mode-gray-400, 1px, bottom, right);
}

.button-quest {
  @include button;
  @include btn-border-anim($quest-600, $quest-600, 1px, bottom, right);
}

.button-warlord {
  @include button;
  @include btn-border-anim($warlord-blood-600, $warlord-blood-600, 1px, bottom, right);
}
.button-dullahan {
  @include button;
  @include btn-border-anim($dullahan-600, $dullahan-600, 1px, bottom, right);
}
.button-boost {
  @include button;
  @include btn-border-anim($boost-600, $boost-600, 1px, bottom, right);
}

.button-pal-secondary {
  @include button;
  background-color: $dark-mode-gray-800;
  border-radius: 1.25em;
  color: $paladin-600;
  &:hover {
    background-color: $paladin-600;
    color: $dark-mode-gray-800;
    cursor: pointer;
    box-shadow: inset 10em 0 0 0 $paladin-600;
    transition: 0.5s linear 0s;
}
}

.primary-pal {
  @include pal-button;
}

.primary-quest {
  @include quest-button;
}

.primary-warlord {
  @include warlord-button;
}

.primary-dullahan {
  @include dullahan-button;
}

.primary-boost {
  @include boost-button;
}

.navbar {
  height: 64px;
  padding: 0;
  .menu {
    margin: 0 auto;
    max-width: 1020px;
  }
  ul {
    padding-bottom: 1em;
  }
}
.navbar-button {
  section {
    align-items: center;
    display: flex;
    font-size: 0.5em;
    justify-content: space-between;
    min-width: 110px;
    width: 100%;
    &:hover {
      opacity: 0.8;
      transition: opacity 0.4s ease-in-out;
    }
    + div div:hover {
      transition: 0.5s ease-in-out;
    }
    + .open {
      color: $base-white;
    }
  }
}
.navbar .quest,
.navbar .warlord,
.navbar .dullahan,
.navbar .boosts,
.navbar .more {
  position: relative;
  &:after {
    background-color: transparent;
    bottom: -0.5em;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 0;
    transition: width .25s ease-in;
  }
  &.active:after,
  &:hover:after {
    transition: width .25s ease-in;
    width: 100%;
  }
}
.navbar .quest{
  &.active {
    color: $quest-500;
    transition: color 0.4s ease-in-out;
    &::after {
      background-color: $quest-600;
    }
  }
  &:hover {
    color: $quest-500;
    transition: color 0.4s ease-in-out;
    &::after {
      background-color: $quest-600;
    }
  }
}

.navbar .warlord {
  &.active {
    color: $warlord-blood-500;
    transition: color 0.4s ease-in-out;
    &::after {
      background-color: $warlord-blood-600;
    }
  }
  &:hover {
    color: $warlord-blood-500;
    transition: color 0.4s ease-in-out;
    &::after {
      background-color: $warlord-blood-600;
    }
  }
}

.navbar .dullahan {
  &.active {
    color: $dullahan-500;
    transition: color 0.4s ease-in-out;
    &::after {
      background-color: $dullahan-600;
    }
  }
  &:hover {
    color: $dullahan-500;
    transition: color 0.4s ease-in-out;
    &::after {
      background-color: $dullahan-600;
    }
  }
}

.navbar .boosts {
  &.active {
    color: $boost-500;
    transition: color 0.4s ease-in-out;
    &::after {
      background-color: $boost-600;
    }
  }
  &:hover {
    color: $boost-500;
    transition: color 0.4s ease-in-out;
    &::after {
      background-color: $boost-600;
    }
  }
}

.navbar .more {
  &.active {
    color: $paladin-500;
    transition: color 0.4s ease-in-out;
    &::after {
      background-color: $paladin-600;
    }
  }
  &:hover {
    color: $paladin-500;
    transition: color 0.4s ease-in-out;
    &::after {
      background-color: $paladin-600;
    }
  }
}

.sub-container.warlord {
  #warlord {
    position: relative;
  }
  background-color: transparent;
  margin-bottom: 60px;
  &::after {
    content: '';
    height: 150px;
    margin-top: 0;
    position: absolute;
    width: 100%;
    z-index: 100;
    background-image: linear-gradient(
      rgba($base-black, 1) 0%,
      rgba($base-black, 1) 40%,
      rgba($base-black, 0.9) 50%,
      rgba($base-black, 0.7) 60%,
      rgba($base-black, 0.5) 70%,
      rgba($base-black, 0.4) 80%,
      rgba($base-black, 0.2) 90%,
      rgba($base-black, 0)  100%
      );
  }
}
.box,
.quest-section,
.warlord-section,
.boost-section {
  .subtitle-secondary {
    @include screen($tablet) {
      margin-top: 1.5em;
    }
  }
}
.sub-section.dullahan {
  .box {
    position: relative;
    z-index: 100;
  }
  #dullahan::after {
    content: '';
    height: 60px;
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    background-image: linear-gradient(
      rgba($base-black, 0) 0%,
      rgba($base-black, 0.2) 50%,
      rgba($base-black, 0.4) 60%,
      rgba($base-black, 0.5) 70%,
      rgba($base-black, 0.7) 80%,
      rgba($base-black, 0.9) 90%,
      rgba($base-black, 1)  100%
      );
  }
}
#dullahan {
  background-image: url('../Components/Modules/Images/dullahan-graphic.png');
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  position: relative;
  height: 100%;
  @include screen($tablet) {
    background-image: url('../Components/Modules/Images/dullahan-mobile.png');
    background-position-y: 0;
    background-size: cover;
  }
  @include screen($smartphone) {
    background-image: url('../Components/Modules/Images/dullahan-mobile.png');
    background-attachment: scroll;
    background-position-y: 0;
    background-size: contain;
  }
  .fixed-img {
    position: fixed;
    top: 210px;
  }
}
#boosts.boost-section {
  margin-top: 0;
}
.supported-container {
  .supported-section {
    margin-bottom: 0;
  }
}
.gradient-bottom-container {
  background: radial-gradient(50% 50% at 50% 120%, rgba(237, 42, 36, 0.40) 0%, rgba(243, 208, 27, 0.00) 100%);
  background-attachment: fixed;
  padding-bottom: 2em;
  &::before {
    content: "";
    position: absolute;
    top: -110px;
    width: 100%;
    height: 110px;
    background-color: transparent;
    background-image: linear-gradient(
      rgba($base-black, 1) 0%,
      rgba($base-black, 0.9) 30%,
      rgba($base-black, 0.5) 70%,
      rgba($base-black, 0.4) 80%,
      rgba($base-black, 0.2) 90%,
      rgba($base-black, 0)  100%
    );
    z-index: 1;
  }
}
.footer {
  padding: 1em 1em 3em 1em;
  img {
    margin-bottom: 0;
  }
  .footer-content {
    display: flex;
    margin: 0 auto;
    max-width: 1020px;
    @include screen($tablet) {
      flex-direction: column;
      img {
        margin-left: 0;
      }
    }
  }
  .footer-links {
    margin-left: 2em;
    justify-content: flex-end;
    gap: 5em;
    @include screen($tablet) {
      margin-left: 0;
      margin-top: 1em;
      justify-content: flex-start;
      gap: 1em;
      div {
        padding-left: 0;
      }
    }
    @include screen($smartphone) {
      margin-left: 0;
      margin-top: 1em;
      justify-content: center;
      gap: 1em;
      div {
        padding-left: 0;
      }
    }
  }
  a {
    color: $dark-mode-gray-300;
    font-family: 'DM Sans Bold', sans-serif;
    font-size: 16px;
    letter-spacing: normal;
    line-height: normal;
    text-decoration: none;
    &:hover {
      color: $paladin-600;
      transition: color 0.4s ease-in-out;
    }
  }
}